import React, { useEffect, useState } from "react"
import { Link, navigate, graphql } from "gatsby"
import "../css/branchOfficesAdversiting.less"
import Info from "../components/Info"
import BackgroundBranchOfficesSantiago from "../images/BackgroundBranchOfficesSantiago.jpg"
import OrangeNavbarLogo from "../images/OrangeNavbarLogo.svg"
import WhiteFooterLogo from "../images/WhiteFooterLogo.svg"
import {
  Row,
  Col,
  Layout,
  Affix,
  Form,
  Input,
  Select,
  Button,
  Popover,
  message,
  Result,
} from "antd";
import GoogleMapReact from "google-map-react"
import sendForm from "../services/sendForm"
import SEO from "../components/seo"
import { handlers } from "../helpers/handlers"
import { useLocation } from "@reach/router"

const { Header, Content, Footer } = Layout;

const BranchOfficesAdversiting = (props) => {
  const {
    data: {
      wpgraphql: { branchoffices, settings },
    },
  } = props

  const { type } = props.pageContext
  const location = useLocation()

  const [branchOffices, setBranchOffices] = useState(branchoffices.nodes)
  const [branchOfficesOfSantiago, setBranchOfficesOfSantiago] = useState()
  const [isCompany, setIsCompany] = useState()
  const [loading, setLoading] = useState(false)
  const [defaultCenter, setDefaultCenter] = useState()
  const [currentBranchOffice, setCurrentBranchOffice] = useState()
  const [showMobileMap, setShowMobileMap] = useState(false)
  const { isMobile } = handlers.useDeviceDetect();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;


  useEffect(() => {
    filterBranchOffices()
  }, [])

  const filterBranchOffices = () => {
    const offices = branchOffices
    const filterOffices = offices.filter(
      (item) =>
        item.fields.name === "Providencia" ||
        item.fields.name === "Santiago" ||
        item.fields.name === "La Florida"
    )
    setBranchOfficesOfSantiago(filterOffices)

    if (!isMobile) {
      setCurrentBranchOffice(filterOffices[0])
    }

    setDefaultCenter({
      lat: parseFloat(filterOffices[0].fields.latitude),
      lng: parseFloat(filterOffices[0].fields.length),
    })
  }

  const zoom = 17

  const handleMap = (event) => {
    setCurrentBranchOffice(event)
    setDefaultCenter({
      lat: parseFloat(event.fields.latitude),
      lng: parseFloat(event.fields.length),
    })

    if (!showMobileMap) {
      setShowMobileMap(true)
    } else {
      setShowMobileMap(false)
    }
  }

  const handleType = (value) => {
    setIsCompany(value === "empresa")
  }

  const MapComponent = ({ text, address, name, phone, mapUrl }) => {
    return (
      <Popover
        title={`Sucursal ${name}`}
        content={
          <div>
            <div>
              Dirección:{" "}
              <a rel="noreferrer" href={mapUrl} target="_blank">
                {address}
              </a>
            </div>
            <div>
              Teléfono: <a href={`tel:${phone}`}>{handlers.splitPhone(phone)}</a>
            </div>
          </div>
        }
      >
        <div className="marker-map">
          <span className="icon-placeholder-icon mr-10"></span>
          {text}
        </div>
      </Popover>
    )
  }

  const [form] = Form.useForm()

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const response = await sendForm({
        name: values.name,
        email: values.email,
        phone: values.phone,
        bussiness_name: values.bussiness_name,
        type: values.userType,
        message: values.message,
        country_code: `${process.env.GATSBY_COUNTRY_VALUE}`
      })

      if (response.status === 200) {
        setLoading(false)
        form.resetFields()
        navigate("/sucursales/santiago/exito")
      } else {
        setLoading(false)
        message.error({
          content: "Ocurrió un error. Inténtalo de nuevo.",
          style: {
            marginTop: "43vh",
          },
        })
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }]

  
  return (
    <Layout>
      {
        (location.pathname === "/sucursales/santiago" || location.pathname === "/sucursales/santiago/") ?
          <SEO
            title={"Centros de Salud en Santiago | Sucursales | CMT Salud"}
            description={"Conoce los Centros de Salud ubicados en Providencia, Santiago y La Florida: Exámenes preocupacionales, Salud ambulatoria y Baterías especiales"}
            dataLayer={{
              userId: "",
              pageId: "sucursales",
              pageType: "page",
              category: "",
            }}
            schemaExtraData={schemaExtraData}
          />
        :
          <SEO
            title={"Éxito | Centros de Salud en Santiago | CMT Salud"}
            description={"Éxito envío formulario nuevos centros CMT en Santiago "}
            dataLayer={{
              userId: "",
              pageId: "sucursales",
              pageType: "page",
              category: "",
            }}
            schemaExtraData={schemaExtraData}
          /> 
      }
      <Info
        device='device-desktop'
        isMobile={isMobile}
        splitPhone={handlers.splitPhone}
        countryCode={countryCode}
      />
      <Affix offsetTop={0}>
        <Header className="header-public-clients" theme="light">
          <div className="logo" style={{ float: "left", marginLeft: 5 }}>
            <Link to="/">
              <img src={OrangeNavbarLogo} alt="CMT logo naranja" height={30} />
            </Link>
          </div>
        </Header>
      </Affix>
      <Content>
        <Row justify="center">
          <Col lg={24} xs={24}>
            <Row
              justify="center"
              className="branch-offices-adversiting-container"
            >
              <Col lg={24} xs={24}>
                <Row justify="center">
                  <Col
                    lg={24}
                    xs={24}
                    className="cmt-background-image-wrapper"
                    style={{
                      backgroundImage: `url('${BackgroundBranchOfficesSantiago}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "20%",
                      height: "93vh",
                      width: "100%",
                    }}
                  >
                    <Row justify="center">
                      <Col
                        lg={24}
                        xs={24}
                        className="branch-offices-text-wapper"
                      >
                        <Row
                          justify="center"
                          align="middle"
                          style={{ height: "100%" }}
                        >
                          <Col xl={20} lg={23} xs={21}>
                            <Row justify="start">
                              <Col lg={12} xs={16}>
                                <h1 className="mt-lg-30 mt-xs-15 mb-0">
                                  Contamos con tres centros ubicados
                                </h1>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={20} lg={23} xs={21}>
                            <h2 className="mb-lg-30 mb-xs-20">
                              Providencia, Santiago y La Florida
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="end" className="form-container">
                      <Col
                        xl={13}
                        lg={12}
                        xs={24}
                        className="form-wrapper pt-20 pb-30 pr-30 pl-30"
                      >
                        {type === "form" ? (
                          <Row>
                            <Col lg={24} xs={24}>
                              <h3 className="mb-lg-0 mb-xs-10">
                                Contáctate con nosotros
                              </h3>
                            </Col>
                            <Col lg={24} xs={24}>
                              <h4 className="mb-lg-15 mb-xs-20">
                                Te responderemos a la brevedad
                              </h4>
                            </Col>
                            <Col lg={24} xs={24}>
                              <Form
                                form={form}
                                onFinish={onFinish}
                                size="large"
                              >
                                <Form.Item
                                  className="mb-15"
                                  name="name"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Nombre y apellido son requeridos.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nombre y apellido" />
                                </Form.Item>
                                <Form.Item
                                  className="mb-15"
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Email es requerido.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Correo electrónico" />
                                </Form.Item>
                                <Form.Item
                                  className="mb-15"
                                  name="phone"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Teléfono es requerido.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Teléfono" />
                                </Form.Item>
                                <Form.Item
                                  className="mb-15"
                                  name="userType"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Seleccione un tipo.",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="¿Empresa o particular?"
                                    onChange={handleType}
                                  >
                                    <Select.Option value="empresa">
                                      Empresa
                                    </Select.Option>
                                    <Select.Option value="particular">
                                      Particular
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                {isCompany && (
                                  <Form.Item
                                    name="bussiness_name"
                                    tooltip="Empresa es requerido"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Empresa es un campo requerido",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Nombre de empresa" />
                                  </Form.Item>
                                )}
                                <Form.Item name="message" className="mb-20">
                                  <Input.TextArea
                                    placeholder="Mensaje"
                                    style={{ height: "100px" }}
                                  />
                                </Form.Item>
                                <Row justify="center">
                                  <Col lg={13} xs={24}>
                                    <Button
                                      loading={loading}
                                      htmlType="submit"
                                      className="send-button"
                                      block
                                    >
                                      Enviar mensaje
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        ) : (
                          <Result
                            status="success"
                            title="¡Gracias por contactarnos!"
                            subTitle="Le confirmamos que hemos recibido su información. En breve nos comunicaremos con usted brindándole una respuesta a su solicitud."
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  justify="center"
                  className="branch-offices-map-container mt-100"
                >
                  <Col lg={24}>
                    <h2 className="text-center">Nuevos centros en Santiago</h2>
                    <h3 className="text-center">
                      Lunes a Viernes de 8:00hs a 17:00hs
                    </h3>
                  </Col>
                  <Col
                    xl={13}
                    lg={17}
                    xs={24}
                    className="mt-30 mb-lg-0 mb-xs-40"
                  >
                    <Row justify="center" gutter={[20, 10]}>
                      {branchOfficesOfSantiago &&
                        branchOfficesOfSantiago.map((branchOffice) => {
                          return (
                            <React.Fragment>
                              <Col
                                lg={8}
                                xs={20}
                                key={branchOffice.id}
                                className={`branch-office-content ${currentBranchOffice &&
                                  branchOffice.fields.name ===
                                  currentBranchOffice.fields.name
                                  ? "change-text-color"
                                  : null
                                  }`}
                                onClick={() => handleMap(branchOffice)}
                              >
                                <Row justify="center">
                                  <Col
                                    lg={24}
                                    xs={24}
                                    className="mb-15"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span className="icon-placeholder-icon" />
                                  </Col>
                                  <Col lg={24} xs={24}>
                                    <h4 className="text-center mb-0">
                                      {branchOffice.fields.name}
                                    </h4>
                                  </Col>
                                  <Col lg={20} xs={18}>
                                    <p className="text-center mb-0">
                                      {branchOffice.fields.address}
                                    </p>
                                  </Col>
                                  <Col lg={20} xs={20}>
                                    <p className="text-center">
                                      {handlers.splitPhone(branchOffice.fields.phone)}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                xs={24}
                                className="mobile-map-wrapper"
                                style={{
                                  display: `${currentBranchOffice &&
                                    branchOffice.fields.name ===
                                    currentBranchOffice.fields.name
                                    ? "flex"
                                    : "none"
                                    }`,
                                }}
                              >
                                {
                                  <div
                                    style={{
                                      height: "calc(100vh - 450px)",
                                      width: "100%",
                                    }}
                                  >
                                    <GoogleMapReact
                                      bootstrapURLKeys={{
                                        key: "AIzaSyCKP-G1YcisnbzHPVGBh6MWF8t9nliNcc8",
                                      }}
                                      center={defaultCenter}
                                      defaultZoom={zoom}
                                    >
                                      <MapComponent
                                        name={branchOffice.fields.name}
                                        phone={branchOffice.fields.phone}
                                        lat={parseFloat(
                                          branchOffice.fields.latitude
                                        )}
                                        lng={parseFloat(
                                          branchOffice.fields.length
                                        )}
                                        address={branchOffice.fields.address}
                                        mapUrl={branchOffice.fields.mapUrl}
                                      />
                                    </GoogleMapReact>
                                  </div>
                                }
                              </Col>
                            </React.Fragment>
                          )
                        })}
                    </Row>
                  </Col>
                  <Col
                    xl={13}
                    lg={17}
                    className="mobile-desktop-wrapper mt-10 mb-40"
                  >
                    {currentBranchOffice && defaultCenter && (
                      <div
                        style={{ height: "calc(100vh - 300px)", width: "100%" }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyCKP-G1YcisnbzHPVGBh6MWF8t9nliNcc8",
                          }}
                          center={defaultCenter}
                          defaultZoom={zoom}
                        >
                          <MapComponent
                            name={currentBranchOffice.fields.name}
                            phone={currentBranchOffice.fields.phone}
                            lat={parseFloat(
                              currentBranchOffice.fields.latitude
                            )}
                            lng={parseFloat(currentBranchOffice.fields.length)}
                            address={currentBranchOffice.fields.address}
                            mapUrl={currentBranchOffice.fields.mapUrl}
                          />
                        </GoogleMapReact>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <Footer>
        <div className="mt-50 pb-30">
          <Row justify="space-between" align="bottom">
            <Col
              lg={{ order: 1, span: 12 }}
              md={{ order: 2 }}
              sm={{ order: 2 }}
              xs={{ order: 2, span: 24 }}
              className="mb-xs-20"
            >
              <img src={WhiteFooterLogo} alt="CTM logo blanco" height={60} />
            </Col>
            <Col
              lg={{ order: 2, span: 12 }}
              md={{ order: 1 }}
              sm={{ order: 1 }}
              xs={{ order: 1, span: 24 }}
            >
              {
                settings && settings.nodes.length > 0 && <Row align="top">
                  <Col lg={12} xs={24}>
                    <h3>Contáctanos</h3>
                    <p>
                      <span className="icon-system-phone mr-10" />
                      <a href={`tel:${settings.nodes[0].fields.centralPhone}`}>
                        {handlers.splitPhone(settings.nodes[0].fields.centralPhone)}
                      </a>
                    </p>
                    <p>
                      <span className="icon-system-mail mr-10 mt-xs-50" />
                      <a href={`mailto:${settings.nodes[0].fields.centralEmail}`}>
                        {settings.nodes[0].fields.centralEmail}
                      </a>
                    </p>
                  </Col>
                  <Col lg={12} xs={24} className="mt-xs-20">
                    <h3>Síguenos</h3>
                    <p className="social-network">
                      <a
                        rel="noreferrer"
                        href={settings.nodes[0].fields.linkedin}
                        target="_blank"
                        aria-label="Linkedin botón"
                      >
                        <span className="icon-brand-linkedin mr-10" />
                      </a>
                      <a
                        rel="noreferrer"
                        href={settings.nodes[0].fields.facebook}
                        target="_blank"
                        aria-label="Facebook botón"
                      >
                        <span className="icon-brand-facebook mr-10" />
                      </a>
                    </p>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </div>
      </Footer>
    </Layout>
  )
}

export default BranchOfficesAdversiting

export const postQuery = graphql`
  query GET_BRANCH_OFFICES_ADS($country: String) {
    wpgraphql {
      branchoffices(where: { orderby: { field: MENU_ORDER, order: ASC }, country: $country }, first: 20) {
        nodes {
          id
          fields {
            name
            address
            phone
            latitude
            length
            mapUrl
          }
        }
      }
      settings(where: { country: $country }) {
        nodes {
          fields {
            country
            centralPhone
            centralEmail
            facebook
            linkedin
          }
        }
      }
    }
  }
`
